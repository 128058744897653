$(document).ready(() => {
    /**
     * Description: Method to make any container an accordion
     * Param: none
     * returns: none
     * requires: jQuery and accordion.scss on page
     * structure <container class="accordion"> with two children, accordion__exp (controls the toggle) and accordion__child (content to show)
     * optional data-conditional-accordion attribute is used when the behavior differs on mobile vs desktop. set to true to activate. omission or false results in normal behavior
     * contained styling is only for animations between classes.
     */
    function accordion() {
        $(document).unbind('click').on('click', '.accordion__exp', function (e) {
            // Hack to prevent anchors in triggers from firing accordion. Probably should have anchors that don't toggle in toggles, but YOLO - WJAY
            if (e.target.nodeName === 'A') {
                e.stopPropagation();
                return;
            }

            e.preventDefault();

            const isDesktop = window.matchMedia('(min-width: 992px)').matches;
            const self = $(this);
            const parent = self.parents('.accordion');
            const parentElement = parent[0];

            let activeClass;
            if (parentElement.dataset.conditionalAccordion && parentElement.dataset.conditionalAccordion === "true") {
                if (isDesktop) {
                    activeClass = 'accordion--active-desktop';
                } else {
                    activeClass = 'accordion--active-mobile';
                }
            } else {
                activeClass = 'accordion--active';
            }

            if (parent.hasClass(activeClass)) {
                cleanAccordionClasses(parent);
            } else {
                parent.addClass(activeClass);
            }
            parent.focus();
        });
    }

    accordion();

    function cleanAccordionClasses(parent) {
        if (parent.hasClass('accordion--active')) {
            parent.removeClass('accordion--active');
        }

        if (parent.hasClass('accordion--active-desktop')) {
            parent.removeClass('accordion--active-desktop');
        }

        if (parent.hasClass('accordion--active-mobile')) {
            parent.removeClass('accordion--active-mobile');
        }
    }

    function checkOthers(elem) {
        document.querySelectorAll('.accordion-controls a.picks__expander').forEach((accordionElm) => {
            if (accordionElm !== elem) {
                if (accordionElm.getAttribute('aria-expanded') === 'true') {
                    accordionElm.setAttribute('aria-expanded', 'false');
                    const content = accordionElm.getAttribute('aria-controls');
                    document.getElementById(content).setAttribute('aria-hidden', 'true');
                    document.getElementById(content).classList.add('d-none');
                }
            }
        });
    }

    function accordionToggle() {
        document.querySelectorAll('.accordion-controls a.picks__expander').forEach((control) => {
            control.addEventListener('click', () => {
                const accordionContent = control.getAttribute('aria-controls');
                checkOthers(control[0]);

                const isAriaExp = control.getAttribute('aria-expanded');
                const newAriaExp = (isAriaExp === 'false') ? 'true' : 'false';
                control.getAttribute('aria-expanded', newAriaExp);
                const contentElm = document.getElementById(`${accordionContent}`);
                const isAriaHid = contentElm.getAttribute('aria-hidden');
                if (isAriaHid === 'true') {
                    contentElm.setAttribute('aria-hidden', 'false');
                    contentElm.classList.remove('d-none');
                } else {
                    contentElm.setAttribute('aria-hidden', 'true');
                    contentElm.classList.add('d-none');
                }
            });
        });
    }

    // call this function on page load
    accordionToggle();
});

// this is a hack for links embeded in Global Methodologies using the internal page choice links
document.addEventListener('DOMContentLoaded', function () {
    // Function to add target="_blank" to links
    function addTargetBlankToLinks() {
        // Get all methodology accordion blocks
        const methodologyBlocks = document.querySelectorAll('.methodology-accordion-block');

        // Loop through each methodology block
        methodologyBlocks.forEach(block => {
            // Find all anchor tags within the block
            const links = block.querySelectorAll('a');

            // Add target="_blank" to each link that doesn't already have a target
            links.forEach(link => {
                // Check if link doesn't have a target attribute
                if (!link.hasAttribute('target')) {
                    link.setAttribute('target', '_blank');
                }
                // If link has a target but it was lost during rendering, restore it
                else if (link.getAttribute('target') === '') {
                    link.setAttribute('target', '_blank');
                }
            });
        });
    }

    // Run initially after DOM content is loaded
    addTargetBlankToLinks();

    // Optional: Run again after a short delay to catch any dynamically loaded content
    setTimeout(addTargetBlankToLinks, 1000);


});
